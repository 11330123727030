import React, { useState, useEffect, useContext } from 'react';

import { TitleIcon } from '@findep/mf-landings-core'
import Grid from '@material-ui/core/Grid';
import { css } from '@emotion/react'
import { Typography, Box, Button } from '@material-ui/core';

import { navigate } from "../../helpers/queryNavigate";

import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import CardFileUploadComponent from '../elements/fileupload/CardFileUploadComponent';
import PropTypes from 'prop-types';
import { GlobalStateFooterContext } from '../../context/GlobalStateFooterContext';
import getTenant from '../../helpers/getTenant';


const textSubtitle = css`        
        text-align: center;
        padding: 1rem;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
`

const rootBtn = css`
        border-radius: 20px;
`

const styleTitle = css`
    margin-bottom: 1rem;
`
const root = css`
    padding: 1rem;
`

const btnContainer = css`
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;        
`

const CargaArchivosDinamicos = (props) => {

    const { setIsDynamic, setTenantFooter } = useContext(GlobalStateFooterContext);    
    const [activate, setActivate] = useState(true);
    const [savefile, setSavefile] = useState({});
    const [data, setData] = useState([]);

    const handleFile = (file, flag) => {
        setSavefile({
            ...savefile,
            [flag]: file.value
        });
    }

    useEffect(() => {
        const getTenantHelper = async () => {
            let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
            let id = flujo?.creditApplicationId
            //GET TENANT        
            const tenantValue = await getTenant(id)
            setIsDynamic(true)
            setTenantFooter(tenantValue)
        }
        getTenantHelper()
    }, [])

    useEffect(() => {
        data.length === Object.values(savefile).length ?
            setActivate(false) :
            setActivate(true)
    }, [data, savefile])

    useEffect(() => {
        const updateMessages = () => {
            setData(props.data.messages)
        }
        updateMessages();
    }, [props.data.messages]);
    // CardFileUpload
    const uploadCard = data.map((item, idx) =>
        <CardFileUploadComponent onChange={handleFile} type={item.display} message={item.display} key={idx} />
    );

    const handleNextStep = () => {
        const navigateURL = props.pageContext.next.path;
        navigate(navigateURL);
    }

    return (
        <>
            <Grid container justify="center">
                <Grid item justify="center">
                    <Grid container css={styleTitle} justify="center">
                        <TitleIcon title={props.title} icon={props.icon} textColor={true} />
                    </Grid>

                    <Typography css={textSubtitle}>
                        {props.subtitle}
                        {/* Favor de subir los documentos solicitados utilizando el componente de "cargar archivo" */}
                    </Typography>

                    <Grid item xs={12} md={12} justify="center" css={root}>
                        {uploadCard}
                        <Box css={btnContainer}>
                            <Button
                                id="comprobante-ingresos-nextStep-button"
                                variant="contained"
                                color="primary"
                                startIcon={<TrendingFlatIcon />}
                                css={rootBtn}
                                disabled={activate}
                                onClick={handleNextStep}
                            >
                                Continuar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <br />
        </>
    );
}


export default CargaArchivosDinamicos;


CargaArchivosDinamicos.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired
}

CargaArchivosDinamicos.defaultProps = {
    title: "Comprobante",
    subtitle: "Favor de subir los documentos solicitados utilizando el componente de 'cargar archivo'"
}


